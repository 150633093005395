.dashboard-container {
  padding-top: 2rem;
  text-align: center;
  min-height: 93.5vh;
  background-color: #fdfdfd;
}

.summary-container {
  padding: 2rem;
  height: 50vh;
  /* overflow-y: auto; */
}

.request-button:hover {
  background-color: rgb(46, 165, 73);
  cursor: pointer;
}

.request-button {
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: rgb(50, 182, 80);
  padding: 12px;
  font-size: 14px;
  border: 1px solid rgb(46, 165, 73);
  border-radius: 4px;
  color: #fff;
}

.request-button:disabled {
  background-color: rgb(50, 182, 80, 0.1);
  border-color: rgb(50, 182, 80, 0.1);
  cursor: auto;
}
