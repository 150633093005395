body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.menu {
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.header {
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.header-container {
  display: flex;
  justify-content: space-between;
  margin-right: -1.5rem;
  color: white;
}

.header-menu {
  margin-top: 1.5rem;
}

.menu {
  padding: 1rem;
  height: 6rem;
  width: 15rem;
}

.logout-button {
  float: right;
}

.logoContainer {
  height: 32px;
  width: auto;
  margin-right: 3rem;
}

.logo {
  max-height: 100%;
  margin-right: 1rem;
  margin-top: -0.45rem;
}

.headerTitle {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
}