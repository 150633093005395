.signIn {
  background-color: rgb(189, 184, 184);
}

.form-container {
  height: 400px;
  width: 400px;
  background-color: #fff;
  box-shadow: 3px 3px 5px gray;
  border-radius: 1rem;
  padding: 0rem 2rem;
}

.logo-container {
  margin: 1rem 0;
  padding: 1rem;
  height: 30%;
  width: 100%;
}

.logo-image {
  height: 100%;
  width: 100%;
}

.input-login {
  margin-bottom: 1rem;
}

.button-login {
  width: 100%;
  color: white;
  background-color: #00255c;
  border-radius: 0.35rem;
  border: 1px solid #00255c;
  padding: 0.5rem;
  text-transform: uppercase;
}

.button-login:hover {
  cursor: pointer;
  background-color: #02327a;
}
